.containerImage {
  display: flex;
  justify-content: center;
}

.imageCar {
  width: 90%;
}

.imageCompany {
  position: absolute;
  bottom: 1rem;
  right: 3rem;
}

.description {
  text-align: right;
}

.textTotal {
  text-align: right;
  font-weight: bold;
  color: #51bc65;
}

.containerBelow {
  margin-top: 1rem;
  margin-inline: 2rem;
}

@media (max-width: 991px) {
  .imageCar {
    width: 80%;
  }

  .description {
    text-align: left;
    margin-top: 1rem;
  }

  .textTotal {
    text-align: left;
  }

  .containerBelow {
    margin-inline: 0rem;
    gap: 1rem;

    & > div {
      margin: 0;
      padding: 0;
    }
  }
}
