.btn-reserva {
  border-radius: 30px !important;
}

.btn-galeria {
  background: #406d9f;
  border: none;
  border-radius: 11px;
  color: #fff;
  font-size: 14px !important;
  font-weight: bold;
  line-height: 20px;
  margin-top: 45px;
  padding: 8px 0px;
  text-align: center;
  text-transform: uppercase;
  width: 165px;
}

.btn-galeria:hover {
  color: #000;
}

.cell-content {
  text-align: center !important;
  padding-top: 7px;
}

.content {
  margin: 0px !important;
  padding: 0px !important;
}

.card {
  border-radius: 0px !important;
}

.label-search {
  margin-top: 25px;
  margin-bottom: -11px;
}

.table-title {
  background-color: #ddd !important;
}

.taps {
  margin: 50px;
}

.taps .nav-link {
  border: 1px solid #000 !important;
  border-radius: 30px !important;
  color: #000 !important;
  font-size: 18px !important;
  letter-spacing: 1px !important;
  margin: 20px !important;
  padding: 0 16px !important;
}

.taps a.active,
.taps .nav-link:hover {
  background-color: #ff9f1a !important;
  border: 1px solid #ff9f1a !important;
  color: white !important;
  cursor: pointer !important;
}

.taps .nav-tabs {
  border-bottom: 0px !important;
}
