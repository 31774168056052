.navbar.navbar {
  padding-block: 0 !important;
  padding-inline: 1rem !important;

  @media (max-width: 991px) {
    background: var(--background-header) !important;
  }

  @media (min-width: 992px) {
    & > :global(.navbar-collapse) {
      visibility: visible;
    }
  }

  & :global(.nav-link) {
    color: black !important;
    font-weight: 600;
    text-transform: uppercase !important;
    padding-inline: 25px !important;

    @media (min-width: 992px) {
      padding-block: 33px !important;
    }

    &:hover,
    &:global(.active),
    &[aria-expanded='true'] {
      color: white !important;
      background-color: var(--button-primary) !important;
    }
  }

  & :global(.dropdown-item:hover) {
    color: white !important;
  }

  & :global(.nav-link.active),
  & :global(.dropdown-item.active) {
    background-color: var(--button-primary) !important;
  }

  & :global(.dropdown-item.active[role='button']:not(:hover)) {
    color: #000;
    background-color: transparent !important;
  }
}

.sticky {
  background: var(--background-header) !important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
  transition: transform 500ms ease, background 500ms ease;
}

.imageLogo {
  height: 4rem;
}
