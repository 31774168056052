$background-color: var(--button-primary-active);
$text-color: var(--color-text);
$text-color-hover: var(--color-text);

.containerCardHotel {
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;

  & > .cardHotel {
    width: 100%;
    height: 100%;
    border-width: 0px;

    & > div.headerCardHotel {
      background-color: transparent;
      border-width: 0px;
    }

    & > div.bodyCardHotel {
      border: 1px solid #00000020;
    }
  }
}

.containerImage {
  padding: 0.5rem;
  display: flex;
  justify-content: center;

  & > :global(.carousel.slide) {
    width: 75%;

    & > :global(.carousel-inner) > :global(.carousel-item) > img {
      aspect-ratio: 1 / 1;
    }
  }
}

.containerAmenities {
  gap: 1rem;
  padding-left: 15px;
  padding-right: 18rem;

  &.showCompetence {
    gap: 0;
    padding-right: 15px;
  }
}

.buttonAmenity {
  border-radius: 1rem;
  box-shadow: 4px 4px 7px var(--button-primary);
  opacity: 1;
}

.imageAmenity {
  height: 1rem;
  aspect-ratio: 1/1;
}

.seeMore {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  & > button {
    color: var(--button-primary);
  }
}

.containerCarPrices {
  box-sizing: border-box;

  & > .cardPrices {
    height: 100%;
    max-height: 100%;
    border-radius: 8px 8px 0px 0px !important;

    & > div.headerCardPrices {
      border-radius: 8px 8px 0px 0px !important;
      background-color: var(--button-primary);

      &.openHeaderCardPrices {
        background-color: var(--button-primary-translucent);
      }

      & > h4,
      h5 {
        margin: 0;
        padding: 0;
        color: var(--color-text);
        font-weight: bold;
        text-align: center;
      }
    }

    & > div.bodyCardPrices {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
    }
  }

  & ~ & {
    & > .cardPrices {
      & > div.bodyCardPrices {
        position: relative;
        background-color: $background-color;
      }
    }
  }
}

.totalPrice {
  color: #51bc65;
  font-weight: normal;
}

.containerImageCompetence {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > img {
    height: 8rem;
  }

  & > h4 {
    color: $text-color;
  }
}

.containerIconCompetence {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: $background-color;

  & > .iconCompetence {
    width: 100%;
    font-size: 2rem;
    color: $text-color;
    transition: transform 0.5s ease-in-out;

    &:hover {
      color: $text-color-hover;
    }

    &.iconHideCompetence {
      transform: rotate(180deg);
    }
  }
}

.containerLoadingPriceComparison {
  background-color: $background-color;
  color: $text-color;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.containerOffers {
  overflow: hidden;
  transition: max-height 0.5s, margin-top 0.5s;
  max-height: 0;
  margin-top: 0;
}

.rowOffers {
  & ~ & {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .containerCardHotel {
    margin-bottom: 1rem;

    & > .cardHotel {
      & > div.headerCardHotel {
        display: none;
      }
    }
  }

  .containerAmenities {
    gap: 0.1rem;
    padding-left: 15px;
    padding-right: 15px;
  }

  .rowOffers {
    & > div ~ div ~ div {
      margin-top: 1rem;
    }
  }
}
