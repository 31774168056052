.image {
  width: 100%;
  aspect-ratio: 2/1;
}

.title,
.subTitle {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  color: black;
}

.subTitle {
  color: var(--button-primary);
}

.submitYourClaim {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: black;
}

.containerForm {
  gap: 1rem;
}

.input {
  & > :global(.form-group) {
    display: flex;

    & > label:global(.form-label) {
      justify-content: flex-start;
    }

    & > * {
      flex-grow: 1;
      width: 50%;
      min-width: none;
      max-width: none;
    }
  }
}
