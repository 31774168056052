.container-info {
  border-radius: 1em !important;
}

.bg-primary-color {
  background-color: #406d9f !important;
}

.bg-secondary-color {
  background-color: #d0d0d0 !important;
}

.bg-blue-sky {
  background-color: #edf7ff !important;
}

.br-right-1 {
  border-right: solid white 1.5px;
}

.condo-map-container {
  margin-top: 1.5em;
}

.datos-monetarios-container {
  color: #363636;
  margin-top: 1.5em;
  opacity: 1;
  padding: 1em !important;
}

.datos-reserva-container {
  color: white;
  padding: 1em !important;
}

.label-totales {
  color: #51bc65;
  font-size: 20px !important;
  font-weight: bold;
}

.section-container {
  padding: 1.3em !important;
}

.section-title {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.text-strong {
  font-weight: bold;
}
