.timeline {
  list-style: none;
  padding: 0px;
}

.timelineItem {
  position: relative;
  padding-top: 0.2rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  margin-left: 10px;
  border-left: 4px solid var(--button-primary);
}

.timelineItem:last-child {
  padding-bottom: 0px;
  border-left-color: transparent;
}

.timelineItem::before {
  content: '';
  position: absolute;
  left: -19px;
  top: 0px;
  width: 34px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: var(--button-primary);
  border: 1px solid var(--button-primary);
}
