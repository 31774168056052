.container {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;

  & > li {
    list-style: none;

    &:not(:global(.rc-pagination-options)) {
      cursor: pointer;
      color: var(--button-primary);
      background-color: #fff;
      border: 1px solid #dee2e6;
      &:has(+ li) {
        // background-color: red !important;
        margin-right: -1px;
      }

      width: 2rem;
      aspect-ratio: 1 / 1;
      display: flex;
      justify-content: center;
      align-items: center;

      &:global(.rc-pagination-disabled) {
        opacity: 0.5;
        pointer-events: none;
        cursor: auto;
      }

      &:global(.rc-pagination-item-active) {
        color: #fff;
        background-color: var(--button-primary);
        border-color: var(--button-primary);
      }
    }
  }
}
