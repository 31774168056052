.card {
  background-color: var(----background-primary-translucent);
  color: black;
  font-size: 1.3rem;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.image {
  width: 100%;
  aspect-ratio: 1;
}

.timer {
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.timer > span {
  margin-left: 0.3rem;
}

.open {
  color: #205485;
}

.close {
  color: #fb1c1b;
}

.rating > div {
  width: fit-content;
}

.rating svg {
  color: var(--button-primary);
}

.price {
  color: #494d55;
}

.cuisine {
  cursor: default;
  color: #acacac;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
