.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rowYourReservationDetail {
  gap: 1rem;
}

.cardBelow {
  & h4 {
    color: var(--button-primary-active);
  }

  & > div {
    background-color: white;
    color: #0000008a;
  }
}

.textTotal {
  font-weight: bold;
  color: #51bc65;
}
