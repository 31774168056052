.footer-1 {
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 13px;
}

.footer-link li a {
  font-size: 1.2rem;
}

.footer-2 {
  position: relative;
  padding: 20px 0;
}
