.welcome-bg {
  background-image: url('../img/welcome-bg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  width: 100%;
  height: auto;
  position: relative;
  padding: 70px 0 120px 0;
}
.bg-welcome-hoteles {
  background-image: url('../img/palmeras.png');
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
  position: relative;
  padding: 70px 0 30px 0;
}
.welcome-hoteles-info h1 {
  font-size: 3rem;
  font-weight: 700;
}
.label-welcome-hoteles {
  font-size: 1.8rem;
  font-weight: 600;
}
.bg-welcome-hoteles::before,
.welcome-bg::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: rgba(0, 0, 0, 0.6);
}
.hovereffect h2 {
  text-transform: uppercase;
  color: #fff;
  position: relative;
  font-size: 28px;
  letter-spacing: 1px;
  margin: 0;
  padding: 0 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.hovereffect img {
  display: block;
  position: relative;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.hovereffect:hover img {
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.bg-welcome-u-minuto {
  background-image: url('../img/bg-word.png');
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
  position: relative;
  padding: 70px 0 30px 0;
}
.bg-subscription {
  background-image: url('../img/subscription-bg.jpg');
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
  position: relative;
  padding: 30px 0;
}
.input-subscription {
  background: transparent !important;
  color: #fff !important;
  border-radius: 25px !important;
}
.input-subscription:focus,
.input-subscription:active {
  box-shadow: none !important;
  border-color: #fff !important;
  outline: none !important;
}
input.input-subscription::placeholder {
  color: #fff !important;
}
.btn-subscription {
  text-transform: uppercase !important;
  border-radius: 25px !important;
}
.accordion-preguntas .card-header {
  background: transparent !important;
  padding: 0 !important;
  border: 0 !important;
}
