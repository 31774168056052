.accordion {
  & > div:global(.card) {
    border-width: 0;

    & > div {
      border-width: 0;

      &:global(.card-header) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;

        &:after {
          content: '\25be';
          float: right;
          line-height: 1rem;
          font-size: 2rem;
          transition: transform 0.5s;
        }

        &:has(+ div:global(.collapse.show)) {
          &:after {
            // color: red;
            transform: rotate(180deg);
          }
        }
      }

      &:global(.card-header),
      &:global(.collapse),
      &:global(.collapsing) {
        background-color: #f8f9fa !important;
      }
    }
  }
}

.policy {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > input {
    margin-block: 0 !important;
  }
}
