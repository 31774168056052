.card {
  border-radius: 35px !important;
  background-color: var(--background-primary-translucent);
}

.rotate {
  transform: rotate(180deg);
}

.itineraryDescription + .itineraryDescription {
  margin-top: 1rem;
}

.sameDepartureDate {
  display: none;
}

.differingDepartureDate {
  color: red;
  font-size: 0.75rem;
}

.flightArrives {
  color: red;
}
