.containerMain {
  background: url(https://cdn.signalnode.io/1602600824907.jpg) center center
    no-repeat !important;
  height: 420px;
}

.title {
  display: block;
  font-weight: 400;
  font-size: 2.625rem;
  color: black;
  text-align: left !important;
}

.call {
  display: block;
  font-weight: 300;
  font-size: 1.75rem;
  color: black;
  text-align: left !important;
}

.boxMessage {
  padding: 1rem;
  height: 100%;
  border: 0.0625rem solid gray;
  background-color: rgba(255, 255, 255, 0.9) !important;
  text-align: center !important;
  border-radius: 1.25%;
  line-height: 1.25rem;

  font-size: 14px;
  font-weight: 400;
  color: black;
}

.weWillBook {
  display: block;
  color: black;
  font-size: 2.375rem !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.plusReward {
  display: block;
  color: black;
  font-size: 1.625rem !important;
  font-weight: 300 !important;
  text-align: center !important;
  margin-bottom: 0.875rem;
}

.titleEvent {
  display: block;
  position: absolute !important;
  top: 185px !important;
  text-align: center !important;
  color: #fff !important;
  font-weight: 300 !important;
  font-size: 1.25rem !important;
  width: 89% !important;
}

.containerBestPrice {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;

  max-height: 425px;
  background: url(https://cdn.signalnode.io/1602600793427.jpg) center center
    no-repeat;
}

.contentBestPrice {
  height: 100%;
  padding: 1.25rem 1.875rem;
  color: #fff;
  text-align: center;
  gap: 3rem;
}

.titleBestPrice {
  font-weight: 400;
  font-size: 2.375rem;
  color: #fff;
  padding-bottom: 1.25rem;
}

.containerHotelsAvailable {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.containerIcon {
  align-self: center;
  width: fit-content;
  height: fit-content;
  background: #fff;
  color: var(--button-primary);
  padding: 1rem;
  border-radius: 100%;

  & > svg {
    font-size: 46px;
  }
}

.containerHowItWorks {
  margin-block: 1rem;
  border: 0.03125rem solid gray;
}

.titleHowItWorks {
  display: block;
  color: black !important;
  padding-bottom: 0.625rem;
  margin-top: 0.625rem;
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  font-size: 2rem;
  font-weight: 300;
}

.descriptionHowItWorks {
  display: block;
  color: black !important;
  font-size: 1.1875rem;
  font-weight: 300;
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  padding-bottom: 0.625rem;
}
