.cardOffer {
  border-top: 8px solid var(--button-primary-translucent);
}

.offerVendor {
  color: var(--button-primary-translucent);
}

.offerPrice {
  color: red;
}
