.counter {
  & * {
    flex-grow: 1 !important;
  }

  & input {
    text-align: center;
    background-color: #fff !important;
    border-color: var(--button-primary);
    color: #000;
  }
}
