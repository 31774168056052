.imageCar {
  width: 100%;
  height: auto;
}

.imageCompany {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 1rem 0.5rem 0;
}

.containerRight {
  gap: 1rem;
}

.columnContainerTotal {
  margin: 0;
  padding: 0;

  @media only screen and (min-width: 1200px) {
    & {
      text-align: right;
    }
  }
}

.columnContainerButton {
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 1200px) {
    & {
      justify-content: flex-end;
    }
  }
}

.textTotal {
  font-weight: bold;
  color: #51bc65;
}
