.image-content {
  text-align: center !important;
}

.image-content img {
  width: 700px !important;
  margin: 10px;
}

.image-item-content img {
  width: 400px !important;
  max-width: 130px;
}

.slick-slide img {
  display: inline !important;
}

.slider-items .slick-current .image-item-content img {
  border-color: #406d9f;
  border-style: solid;
  border-width: 5px;
  opacity: 0.2;
}

.slider-items .slick-arrow {
  color: #406d9f;
}
