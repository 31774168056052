.containerFilters {
  gap: 1rem;
  justify-content: center;
}

.containerCars {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
