.bg_white {
  background: #ffffff;
}
.bg_light {
  background: #f7fafa;
}
.bg_black {
  background: #000000;
}
.bg_dark {
  background: #406d9f;
}
.email-section {
  padding: 2.5em;
}

/*BUTTON*/

.btn.btn-primary2 {
  border-radius: 5px;
  color: #1f6571;
  margin: 10px;
}
.btn.btn-white {
  border-radius: 5px;
  background: #ffffff;
  color: #000000;
}
.btn.btn-white-outline {
  border-radius: 5px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.btn.btn-black-outline {
  border-radius: 0px;
  background: transparent;
  border: 2px solid #000;
  color: #000;
  font-weight: 700;
}
.btn-custom {
  color: rgba(0, 0, 0, 0.3);
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Work Sans', sans-serif;
  color: #0000008a;
  margin-top: 0;
  font-weight: 400;
}

body {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.4);
}

a {
  color: #406d9f;
}

table {
}
/*LOGO*/

.logo h1 {
  margin: 0;
}
.logo h1 a {
  color: #406d9f;
  font-size: 24px;
  font-weight: 700;
  font-family: 'Work Sans', sans-serif;
}

/*HERO*/
.hero {
  position: relative;
  z-index: 0;
}

.hero .text {
  color: #ffffff;
}
.hero .text h2 {
  color: #000;
  font-size: 34px;
  margin-bottom: 15px;
  font-weight: 300;
  line-height: 1.2;
}
.hero .text h3 {
  font-size: 24px;
  font-weight: 600;
}
.hero .text h2 span {
  font-weight: 600;
  color: #000;
}

/*PRODUCT*/
.product-entry {
  display: block;
  position: relative;
  float: left;
  padding-top: 20px;
}
.product-entry .text {
  width: calc(100% - 125px);
  padding-left: 20px;
}
.product-entry .text h3 {
  margin-bottom: 0;
  padding-bottom: 0;
}
.product-entry .text p {
  margin-top: 0;
}
.product-entry img,
.product-entry .text {
  float: left;
}

ul.social {
  padding: 0;
}
ul.social li {
  display: inline-block;
  margin-right: 10px;
}

/*FOOTER*/

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.5);
}
.footer .heading {
  color: #000;
  font-size: 20px;
}
.footer ul {
  margin: 0;
  padding: 0;
}
.footer ul li {
  list-style: none;
  margin-bottom: 10px;
}
.footer ul li a {
  color: rgba(0, 0, 0, 1);
}

@media screen and (max-width: 500px) {
}

/* Pricing table*/
#title {
  text-align: center;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 100px;
}
.hover-table-layout {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
}
.listing-item {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  float: left;
  background: #fff;
  border-radius: 10px;
  z-index: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}
.listing-item:hover,
.listing-item.active {
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  transform: scale(1.03);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 2;
}
.listing-item .listing {
  padding: 20px;
  position: relative;
}
.listing-item .listing:before {
  content: '';
  position: absolute;
  top: -15px;
  left: -o-calc(50% - 15px);
  left: -moz-calc(50% - 15px);
  left: -webkit-calc(50% - 15px);
  left: calc(50% - 15px);
  border-bottom: 20px solid #fff;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

figcaption h1 {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
}
figcaption p {
  color: white;
  font-size: 12px;
}
.listing h1 {
  font-size: 33px;
  text-align: left;
  font-weight: bold;
  margin: 10px 0px;
}
.listing h1:not(:last-child) {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}
.listing h2 {
  font-size: 23px;
  text-align: left;
  font-weight: bold;
  margin: 10px 0px;
}

.listing h3 {
  font-size: 15px;
  text-align: left;
  font-weight: bold;
  margin: 10px 0px;
}
.listing h3:not(:last-child) {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.listing h4 {
  font-size: 13px;
  text-align: left;
  padding: 5px 5px;
  font-weight: normal;
  margin-top: 5px;
}
.listing h4:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.listing-item:hover figure.image figcaption {
  background: rgba(133, 194, 198, 0.3);
}
.listing .button {
  display: inline-block;
  margin: 3px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  margin: 10px 0px;
}

@media (max-width: 767px) {
  .apple-btn {
    padding: 2px 5px !important;
    width: 140px !important;
  }
  .play-btn {
    left: 24%;
  }
}
/*termina  Pricing table*/

/*SERVICES*/
.services {
  background: rgba(0, 0, 0, 0.03);
}
.text-services {
  padding: 20px 10px 0;
  text-align: center;
}
.text-services h3 {
  font-size: 16px;
  font-weight: 600;
}

.services-list {
  padding: 0;
  margin: 0 0 20px 0;
  width: 100%;
  float: left;
}

.services-list img {
  float: left;
}
.services-list .text {
  width: calc(100% - 60px);
  float: right;
}
.services-list h3 {
  margin-top: 0;
  margin-bottom: 0;
}
.services-list p {
  margin: 0;
}
.email-section {
  padding: 2.5em;
}
