.item-title {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.item-content {
  font-size: 15px;
  text-align: center;
}

.item-costo {
  font-size: 15px;
  text-align: center;
}

.slider-content {
  margin-top: 35px;
  margin-bottom: 25px;
}

.slider-items .item {
  width: auto !important;
  height: auto !important;
  border: 1px solid #000 !important;
  border-radius: 30px !important;
  color: #000 !important;
  font-size: 18px !important;
  letter-spacing: 1px !important;
  margin: 40px !important;
  padding: 0 16px !important;
}

.slider-items .slick-current .item {
  background-color: #ff9f1a;
  border: 1px solid #ff9f1a !important;
  color: white !important;
}

.slider-items .slick-arrow {
  color: #406d9f;
}

.room-type {
  background-color: #444;
  border-radius: 10px;
  color: #fff;
  display: inline-block !important;
  padding: 0px 5px;
  font-weight: 600;
  font-size: 20px;
  width: auto;
}

.room-descuento-title {
  color: #51bc65;
}

.room-descuento,
.room-total {
  color: #51bc65;
  font: 20px 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
  padding-top: 15px;
}
