.containerMenu {
  margin: 0;
  width: 100%;
  max-height: 15rem;
  overflow: scroll;
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px !important;
}

.container {
  gap: 0.5rem;
}

.room {
  font-weight: bold;
  color: #000;
  margin: 0;
}

.containerRoomTitle,
.containerGuest,
.containerChild,
.containerButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerCounter {
  width: 50%;
}

.containerChild > select {
  width: 45%;
}

.containerButtonsLast {
  flex-direction: column;
  align-items: flex-end;
}
