.detail-content h1,
.detail-content h2,
.detail-content h3,
.detail-content h4,
.detail-content h5,
.detail-content h6 {
  color: black;
}

.detail-content span,
.detail-content div {
  color: #000000b3;
}
