.container {
  margin: 0 !important;
}

.container > div {
  padding: 0 0 1rem 0 !important;
  margin: 0 !important;
}

.identifier {
  background-color: var(--background-primary-translucent);
}

.rotate {
  transform: rotate(180deg);
}
