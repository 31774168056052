.home-slide {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.box-search {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.box-search nav {
  border: 0;
}

.box-search nav a {
  text-transform: uppercase;
  border: none !important;
  border-radius: 0 !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  margin: 0 !important;
}

.box-search > nav > a + a {
  margin-left: 6px !important;
}

.form-padding {
  padding: 60px 0 70px;
}

.slider_area {
  position: relative;
}

.slider-wrapper {
  overflow: hidden;
}

@media (max-width: 576px) {
  .slider-wrapper {
    height: 592px;
  }
}

@media (min-width: 576px) {
  .slider-wrapper {
    height: 592px;
  }
}

@media (min-width: 1200px) {
  .slider-wrapper {
    height: 760px;
  }
}

.slider-wrapper > img {
  width: 100%;
  height: 100%;
}
