.container {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.address {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.accordion,
.accordionRoom {
  & > div:global(.card) {
    border-width: 0;

    & > div {
      border-width: 0;

      &:global(.card-header) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;

        &:after {
          content: '\25be';
          float: right;
          line-height: 1rem;
          font-size: 2rem;
          transition: transform 0.5s;
        }

        &:has(+ div:global(.collapse.show)) {
          &:after {
            // color: red;
            transform: rotate(180deg);
          }
        }
      }

      &:global(.card-header),
      &:global(.collapse),
      &:global(.collapsing) {
        background-color: #f8f9fa !important;
      }
    }
  }
}

.check {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > input {
    margin-block: 0 !important;
  }
}

.accordionRoom.accordionRoom {
  & > :global(.card) {
    border: 1px solid #00000020 !important;
    background-color: #fff;

    & > div {
      &:global(.card-header),
      &:global(.collapse),
      &:global(.collapsing) {
        background-color: #fff !important;
      }
    }
  }
}
