.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.containerShowCompetence,
.containerSearchByHotel,
.containerPay {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.txtShowCompetence,
.txtSearchByHotel,
.pay {
  font-size: 16px;
  letter-spacing: 0px;
  color: rgb(255, 255, 255);
  line-height: 24px;
}

.containerPayRadioButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pay {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > input {
    margin-block: 0 !important;
  }
}
