.card {
  border: 5px solid var(--button-primary) !important;
  border-radius: 10px !important;
  box-shadow: 2px 3px 6px var(--button-primary) !important;
}

.cardPanel {
  border: 0px solid var(--button-primary) !important;
}

.priceBase {
  color: var(--button-primary-active);
}

.total {
  color: var(--button-primary);
}

.btn {
  border-radius: 1rem !important;
}
