.clicked {
  cursor: pointer;
}

h2.clicked:hover {
  text-decoration: underline;
}

.filtro-container {
  margin-top: 10%;
  margin-bottom: 5%;
}
.filtro {
  background-color: #6c757d !important;
}

.form-padding {
  padding: 30px 20px 30px 20px;
}
